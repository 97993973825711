<template>
	<div>
		<router-view></router-view>
		<TabbarHtml v-bind:active="0" />
	</div>
</template>

<script>
import TabbarHtml from '@/components/tab.vue'
// import Vue from 'vue';
export default {
	components: {
		TabbarHtml,
	},
}
</script>
