import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			redirect: '/buy',
		},
		{
			path: '/buy',
			component: () => import('../views/buy.vue'),
		},
		{
			path: '/find',
			component: () => import('../views/find.vue'),
		},
	],
})
