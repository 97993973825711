<template>
	<div class="tabbar">
		<van-tabbar v-model="tabbarTempValue" @change="onChange">
			<van-tabbar-item icon="cart-o">提货</van-tabbar-item>
			<van-tabbar-item icon="search">查询</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
import Vue from 'vue'
import { Tabbar, TabbarItem } from 'vant'
import { Icon } from 'vant'
import { Notify } from 'vant'

Vue.use(Notify)
Vue.use(Tabbar).use(TabbarItem)
Vue.use(Icon)

export default {
	props: {
		active: Number,
	},
	data() {
		return {
			tabbarTempValue: this.active,
		}
	},
	methods: {
		onChange(index) {
			const routerArray = ['/buy', '/find']
			this.$router.push(routerArray[index])
		},
	},
}
</script>
